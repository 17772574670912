import { navigate } from 'gatsby'
import localStorage from 'localStorage'
import { clearToken, get } from '../utils/request'

export const logout = () => {
  clearToken()
  localStorage.setItem('USER_TYPE', 'not loggedIn')
  navigate('/login')
}

export const getTrafficByDay = async (year, month, day) => {
  const res = await get('managers/traffic/by_day', { year, month, day })
  return res
}

export const trafficIndex = async (year, month) => {
  const res = await get('managers/traffic', { year, month })
  return res
}
